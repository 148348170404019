<template>
  <div>
    <div class="w3-card login">
      <div style="margin: auto; text-align: center">
        <img
          src="logo3.svg"
          style="width: 100px; margin-top: 20px; margin-bottom: 20px"
        >
      </div>
      <div v-if="!resetSent()">
        <label>{{ $t("login.userName") }}</label>
        <input
          v-model="email"
          class="w3-input w3-border w3-round-large"
          type="text"
          :placeholder="$t('login.userName')"
          style="margin-bottom: 1em"
          required
        >
        <button
          class="w3-block w3-button w3-green"
          style="border-radius: 38px"
          type="submit"
          @click="sendReset"
          :disabled="email == ''"
        >
          {{ $t("forgotPassword.sendButton") }}
        </button>
      </div>
      <div v-else-if="resetSent()">
        If there is a user with the email address, you will receive an email with the reset instructions shortly.
        <a href="#/login">Click here to log in</a>
      </div>
    </div>
  </div>
</template>

<script>
import PWReset from "./apiClient/pwReset";
export default {
  name: "ForgotPassword",
  data: function () {
    return {
      email: "",
      sent: false,
    };
  },
  methods: {
    sendReset: function () {
      var self = this;
      PWReset.post({ email: this.email }).then(function (data) {
        if (data.status != 500) {
          self.sent = true
        }
      });
    },
    resetSent: function() {
      return this.sent
    }
  },
};
</script>

<style>

</style>
