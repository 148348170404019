<template>
  <div>
    <div class="w3-card login">
    <form>
      <div style="margin: auto; text-align: center">
        <img
          src="logo3.svg"
          style="width: 100px; margin-top: 20px; margin-bottom: 20px"
        />
      </div>
      <div
        :class="{
          'error-message': badLogin,
          'error-message-hidden': !badLogin,
        }"
      >
        Wrong password or user name
      </div>
      <div>
        <label>{{ $t("login.userName") }}</label>
        <input
          v-model="user.email"
          class="w3-input w3-border w3-round-large"
          type="text"
          :placeholder="$t('login.userName')"
          style="margin-bottom: 1em"
        />
      </div>

      <div>
        <label for="">{{ $t("login.password") }}</label>
        <span style="float: right">
          <a href="#/forgot">Forgot password</a>
        </span>
      </div>

      <input
        v-model="user.password"
        class="w3-input w3-border w3-round-large"
        type="password"
        :placeholder="$t('login.password')"
        style="margin-bottom: 2em"
      />
      <button
        class="w3-block w3-button w3-green"
        style="font-size: 24px; border-radius: 38px"
        type="submit"
        @click="login"
        :disabled="user.password == '' || user.email == ''"
      >
        {{ $t("login.loginButton") }}
      </button>
    </form>
    </div>
  </div>
</template>

<script>
import Login from "./apiClient/login";
import Settings from "./apiClient/settings";
import LanguageFunctions from "./languageFunctions";

export default {
  name: "Login",
  data: function () {
    return {
      user: {
        email: "",
        password: "",
      },
      badLogin: false,
      loaded: false,
    };
  },
  methods: {
    login: function () {
      var self = this;
      Login.post({ user: this.user }).then(function (data) {
        if (data.ok) {
          Settings.get().then((resp) => {
            LanguageFunctions.set(resp.language);
            self.$root.$i18n.locale = resp.language;
            localStorage.setItem("loggedIn", true);
            self.$router.push("/");
          });
        } else {
          self.badLogin = true;
        }
      });
    },
  },
};
</script>

<style>
.login {
  margin: auto;
  max-width: 350px;
  margin-top: 10vh;
  transition: 0.2s ease-in;
}

@media only screen and (max-width: 600px) {
  .login {
    max-width: 100%;
    margin-top: 10vh;
  }
}

.error-message {
  visibility: visible;
  opacity: 1;
  border: 1px;
  border-radius: 4px;
  background-color: orange;
  padding: 14px;
  margin-bottom: 10px;
  transition: visibility 0s, opacity 0.5s linear;
  animation: shake 2s;
  animation-iteration-count: 3;
}

.error-message-hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}
</style>
