<template>
  <!--<div class="transactions-container"> -->
  <div class="w3-row-padding">
    <div class="w3-col">
      <h1>{{ $t("menu.transactions") }}</h1>
      <h4>{{ $t("transactions.pageDesc") }}</h4>
      <div class="w3-card">

        <div style="padding-bottom: 10px">
          <label for="transactionInput">
            {{ $t("transactions.transactions") }}
            <i id="trtippy" class="far fa-question-circle" />
          </label>
          <div v-if="errorMessage != ''">{{ $t(errorMessage) }}</div>
          <input id="transactionInput" v-model="transactionString" :placeholder="$t('transactions.placeholder')"
            autocomplete="off" autofocus maxlength="100" required class="w3-input" type="text" @keyup.enter="send" />
          <hr />
        </div>

        <div class="transactions-table w3-responsive">
          <!-- <v-pagination :length="pageSize" v-model="currentPage" @update:modelValue="loadNextPage"></v-pagination> -->
          <table class="w3-table w3-bordered w3-striped w3-hoverable">
            <thead>
              <tr>
                <th scope="col">
                  {{ $t("transactions.description") }}
                </th>
                <th scope="col">
                  {{ $t("transactions.value") }}
                </th>
                <th scope="col">
                  {{ $t("transactions.date") }}
                </th>
                <th scope="col">
                  {{ $t("transactions.action") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(tr, index) in transactions" :key="index">
                <td v-if="!editMode(index)">
                  {{ tr.desc }}
                </td>
                <td v-else>
                  <input id="trupdate" v-model="tr.desc" type="text" name="trupdate" @keyup.enter="update(tr.id, index)"
                    @keyup.esc="edit(index)" />
                </td>
                <td v-if="!editMode(index)">
                  {{ moneyFormat(tr.value) }}
                </td>
                <td v-else>
                  <input id="trvalue" v-model="tr.value" type="number" name="trvalue"
                    @keyup.enter="update(tr.id, index)" @keyup.esc="edit(index)" />
                </td>
                <td v-if="!editMode(index)">
                  {{ dateFormat(tr.created_at) }}
                </td>
                <td v-else>
                  <input id="trdate" v-model="tr.created_at" type="date" name="trdate"
                    @keyup.enter="update(tr.id, index)" @keyup.esc="edit(index)" />
                </td>
                <td class="td-delete">
                  <i class="far fa-edit tr-delete" style="margin-right: 5px" @click="edit(index)" />
                  <i class="far fa-trash-alt tr-delete" @click="deleteTransaction(tr.id, index)" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- <div class="w3-responsive">
          <v-data-table-server :sticky="true" v-model:items-per-page="itemsPerPage" :headers="headers"
            v-model:page="currentPage" :items="transactions" :items-length="totalPages" item-value="id"
            :loading="loading" @update:options="loadData"></v-data-table-server>
        </div> -->
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import Transactions from "../apiClient/transactions";
import Formatter from "../utils/formatters.js";

export default {
  name: "Transactions",
  data: function () {
    return {
      transactions: [],
      transactionString: "",
      errorMessage: '',
      currentPage: 1,
      itemsPerPage: 10,
      totalPages: 0,
      loading: true,
      headers: [{
        title: 'Dessert (100g serving)',
        align: 'start',
        sortable: true,
        key: 'desc',
      },
      {
        title: 'Dessert (100g serving)',
        align: 'start',
        sortable: true,
        key: 'value',
      },
      {
        title: 'Dessert (100g serving)',
        align: 'start',
        sortable: true,
        key: 'created_at',
      }
      ]
    };
  },
  mounted() {
    this.loadData()
    let content = this.$t("transactions.tooltip");
    this.tippy("#trtippy", {
      content: content,
      trigger: "click",
    });
  },
  methods: {
    loadData() {
      // if (!options) {
      //   return
      // }
      this.loading = true
      var self = this
      //console.log(options); { page: options.page, page_size: options.itemsPerPage }
      Transactions.get().then(function (data) {
        self.transactions = data.data;
        self.totalPages = data.total_pages
        for (var i = 0; i < self.transactions.length; i++) {
          self.transactions[i]["created_at"] = Formatter.date(self.transactions[i]["created_at"])
        }
        self.loading = false
      });
    },
    send() {
      if (!this.transactionString) {
        this.errorMessage = 'empty_transaction'
        return;
      }
      var self = this;
      Transactions.post({ transactionString: this.transactionString })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data.message) {
            self.errorMessage = data.message
          } else {
            self.errorMessage = ''
            self.transactionString = "";
            //data.created_at = Formatter.date(data.created_at);
            self.transactions.unshift(data);
            self.emitter.emit("UpdateCurrentCash");
          }
        })
    },
    update(id, index) {
      var self = this;
      Transactions.put(id, { transaction: this.transactions[index] }).then(
        function () {
          self.transactions[index].editMode = false;
          self.emitter.emit("UpdateCurrentCash");
        }
      );
    },
    moneyFormat: Formatter.money,
    dateFormat: Formatter.date,
    deleteTransaction(id, index) {
      var self = this;
      Transactions.remove(id).then(function () {
        self.transactions.splice(index, 1);
        self.emitter.emit("UpdateCurrentCash");
      });
    },
    editMode(index) {
      return this.transactions[index].editMode;
    },
    edit(index) {
      this.transactions[index].editMode = !this.transactions[index].editMode;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

.transactions-container {
  background-color: white;
  padding: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 15px;
}

.transactions-table {
  max-height: 71vh;
  overflow-y: scroll;
}
</style>
