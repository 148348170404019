<template>
  <label for="recurringTransactionInput"
    >{{ $t("rt.recurringTransactions") }}
    <i id="trtippy" class="far fa-question-circle" />

    <div v-if="errorMessage != ''">{{ $t(errorMessage) }}</div>
  </label>
  <input
    id="recurringTransactionInput"
    v-model="recurringTransactionString"
    class="w3-input"
    :placeholder="$t('rt.placeholder')"
    type="text"
    @keyup.enter="send"
  />
  <hr />
  <div class="w3-responsive">
    <table class="w3-table w3-bordered w3-striped w3-hoverable">
      <thead>
        <tr>
          <th scope="col">
            {{ $t("rt.description") }}
          </th>
          <th scope="col">
            {{ $t("rt.value") }}
          </th>
          <th scope="col">
            {{ $t("rt.action") }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(tr, index) in transactions" :key="tr.id">
          <td>{{ tr.desc }}</td>
          <td class="money-row">
            <p>{{ moneyFormat(tr.value) }}</p>
            <p>({{ moneyFormat(tr.value * 12) }} / {{ $t('year') }})</p>
          </td>
          <td class="td-delete">
            <i
              class="far fa-trash-alt tr-delete"
              @click="deleteTransaction(tr.id, index)"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import RT from "../apiClient/recurringTransactions";
import Formatter from "../utils/formatters.js";

export default {
  name: "RecurringTransactions",
  data: function () {
    return {
      transactions: [],
      recurringTransactionString: "",
      errorMessage: "",
    };
  },
  created: function () {
    var self = this;
    RT.get().then(function (data) {
      self.transactions = data;
    });
  },
  mounted() {
    let content = this.$t("rt.tooltip");
    this.tippy("#trtippy", {
      content: content,
      trigger: "click",
    });
  },
  methods: {
    send: function () {
      if (this.recurringTransactionString == "") {
        this.errorMessage = "empty_transaction";
        return;
      }

      var self = this;
      RT.post({ input: this.recurringTransactionString }).then(function (data) {
        if (data.message) {
          self.errorMessage = data.message;
        } else {
          self.errorMessage = "";
          self.recurringTransactionString = "";
          self.transactions.push(data);
          self.emitter.emit("UpdateChart");
          self.emitter.emit("UpdateCurrentCash");
        }
      });
    },
    updateTransaction: function (id, index) {
      console.log(id, index);
      //var self = this;
      // axios
      //   .put(transactionsEndpoint + "/" + id, {
      //     transaction: this.transactions[index],
      //   })
      //   .then(function () {
      //     self.emitter.emit("UpdateChart");
      //     self.emitter.emit("UpdateCurrentCash");
      //   });
    },
    moneyFormat: Formatter.money,
    deleteTransaction: function (id, index) {
      var self = this;
      this.transactions.splice(index, 1);
      RT.remove(id).then(function () {
        self.emitter.emit("UpdateChart");
        self.emitter.emit("UpdateCurrentCash");
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.money-row p {
  margin: 0;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
