<template>
  <div class="w3-row-padding">
    <div class="w3-col w3-half w3-center">
      <label
        for="forecastLength"
        style="padding-left: 40px"
      >{{ $t("forecast.forecastLength") }}
        <i
          id="frtippy"
          class="far fa-question-circle"
        /></label>
      <input
        id="forecastLength"
        v-model="length"
        class="w3-input mali-forecast"
        type="number"
        min="1"
        ref="forecastLength"
      >
    </div>
    <div class="w3-col w3-half">
      <button
        class="mali-forecast-button"
        @click="updateChart"
        :disabled="length <= 0"
      >
        {{ $t("forecast.button") }}
      </button>
    </div>
  </div>

  <div>
    <canvas
      id="myChart"
      width="600"
    />
  </div>

  <div class="w3-row-padding">
    <!-- <table class="w3-table w3-bordered">
      <thead>
        <tr>
          <th scope="col">{{ $t("rt.description") }}</th>
          <th scope="col">{{ $t("rt.value") }}</th>
          <th scope="col">{{ $t("rt.percentage") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="tr in stats.breakdown" :key="tr.id">
          <td>{{ tr.desc }} x {{ length }}</td>
          <td>{{ moneyFormat(tr.sum) }}</td>
          <td>
            {{ tr.percentage }}
          </td>
        </tr>
      </tbody>
    </table>
    <div>Allocated: {{ stats.allocated }}</div>
    <div>Unallocated: {{ stats.unallocated }}</div> -->
    <hr>
    <div>
      <h3>Details</h3>
      <canvas
        id="doughnut"
        width="600"
      />
    </div>
  </div>
</template>

<script>
import Forecast from "../apiClient/forecast";
import Chart from "chart.js";
import Formatter from "../utils/formatters.js";
import 'chartjs-plugin-colorschemes';

export default {
  name: "Forecast",
  data: function () {
    return {
      length: 24,
      forecastResult: "",
      stats: [],
      chart: null,
      doughnut: null,
    };
  },
  created: function () {
    this.emitter.on("UpdateChart", () => {
      this.updateChart();
    });
    this.getForecast();
  },
  mounted() {
    let content = this.$t("forecast.tooltip");
    this.tippy("#frtippy", {
      content: content,
      trigger: "click",
    });
  },
  methods: {
    getForecast: function () {
      var self = this;
      Forecast.post({ length: this.length }).then(function (data) {
        self.stats = data.stats;
        let ctx = document.getElementById("myChart").getContext("2d");
        self.chart = new Chart(ctx, {
          type: "bar",
          responsive: true,
          data: {
            labels: data.chart.labels,
            datasets: [
              {
                label: "Cashflow",
                data: data.chart.data,
                fill: true,
                borderColor: "#8e80a6",
                tension: 0.1,
                borderJoinStyle: "round",
                pointHoverRadius: 10,
                backgroundColor: "#a9c6c9",
              },
            ],
          },
          options: {
            tooltips: {
              callbacks: {
                label: function (item) {
                  return Formatter.money(item.value);
                },
              },
            },
          },
        });

        let ctx2 = document.getElementById("doughnut").getContext("2d");
        let dnData = data.stats.breakdown.map((x) => x.percentage);
        dnData.push(data.stats.unallocated)
        let dnLabels = data.stats.breakdown.map((x) => x.desc);
        dnLabels.push('Unallocated')
        self.doughnut = new Chart(ctx2, {
          type: "doughnut",
          responsive: true,
          data: {
            labels: dnLabels,
            datasets: [
              {
                label: "Usage",
                data: dnData,
                fill: true,
                tension: 0.1,
                borderJoinStyle: "round",
              },
            ],
          },
          options: {
            tooltips: {
              callbacks: {
                label: function (item, data) {
                  return data.labels[item.index] + ': ' + Formatter.percentage(data.datasets[0].data[item.index]);
                },
              },
            },
          },
        });
      });
    },
    updateChart: function () {
      var self = this;
      Forecast.post({ length: this.length }).then(function (data) {
        self.forecastResult = data;
        self.stats = data.stats;
        self.chart.events = [];
        self.chart.data.labels = data.chart.labels;
        self.chart.data.datasets[0].data = data.chart.data;
        self.chart.update();

        let dnData = data.stats.breakdown.map((x) => x.percentage);
        dnData.push(data.stats.unallocated)
        let dnLabels = data.stats.breakdown.map((x) => x.desc);
        dnLabels.push('Unallocated')
        self.doughnut.events = [];
        self.doughnut.data.labels = dnLabels;
        self.doughnut.data.datasets[0].data = dnData;
        self.doughnut.update();
      });
    },
    moneyFormat: Formatter.money,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.mali-forecast,
.mali-forecast:focus {
  width: 105px;
  height: 95px;
  text-align: center;
  font-size: 36px;
  margin: auto;
}

.mali-forecast-button {
  height: 120px;
  width: 100%;
  border: none;
  border-radius: 40px;
  background-color: #51ae56;
  font-size: 33px;
  cursor: pointer;
  box-shadow: 1px 2px 8px;
  transition: ease-in-out 0.2s;
}
</style>
